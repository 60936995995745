import React from 'react';

function YetiPage(){
    return(
    <div className="container">
            <table cellspacing="02" >
			<tbody>
				<tr>
					<td colspan="2" height="30" align="center" valign="bottom" bgcolor="#FFFFFF"><b><u><font size="5" color="#FF0000">MMY Sheet</font></u></b></td>
					<td align="left" valign="bottom" bgcolor="#FFFFFF"><font color="#000000"></font></td>
				</tr>
				<tr>
					<td height="30" align="center" valign="bottom" bgcolor="#E8E7FC"><b><u><font size="3" color="#0000FF"><a href="https://docs.google.com/spreadsheets/d/1c2jX9Z7vK1ukthcEuX9qC9wvlPaK7hWi2A--pMgGNNY/">MMY INC Inventory File</a></font></u></b></td>
					<td align="center" valign="bottom" bgcolor="#E8E7FC"><b><u><font size="3" color="#0000FF"><a href="https://www.dropbox.com/scl/fi/awyonm3glsddlpms3ylw9/MMY-SPREAD-SHEET.xlsx?dl=0&amp;rlkey=3pgd7jb2xxstqw997jie6i08u">MMY Spread Sheet</a></font></u></b></td>
					<td align="center" valign="bottom" bgcolor="#E8E7FC"><b><font size="3" color="#0000FF"><br></br></font></b></td>
				</tr>
				<tr>
					<td height="30" align="center" valign="bottom" bgcolor="#FFFFFF"><b><u><font size="3" color="#1155CC"><br></br></font></u></b></td>
					<td align="center" valign="bottom" bgcolor="#FFFFFF"><b><u><font size="3" color="#1155CC"><br></br></font></u></b></td>
					<td align="center" valign="bottom" bgcolor="#FFFFFF"><b><u><font size="3" color="#1155CC"><br></br></font></u></b></td>
				</tr>
				<tr>
					<td height="30" align="center" valign="bottom" bgcolor="#E8E7FC"><b><u><font size="3" color="#1155CC"><a href="https://outlook.office365.com/mail/inbox/id/AAQkADRiYmY2ZGQxLWExZmYtNDcxYS05NWU1LTlhOTRlN2IwYzgyMAAQAPmMih7PgaVHl4LR518XaMU%3D">Locco decor Hotmail</a></font></u></b></td>
					<td align="center" valign="bottom" bgcolor="#E8E7FC"><b><u><font size="3" color="#1155CC"><a href="https://docs.google.com/spreadsheets/d/1Sy9ajzukHWc3PkGO2iL46yzh4z_-j69Y9GuJPUQcOmQ/edit#gid=1110025474">random editing sheet</a></font></u></b></td>
					<td align="center" valign="bottom" bgcolor="#E8E7FC"><b><u><font size="3" color="#000000"><br></br></font></u></b></td>
				</tr>
				<tr>
					<td height="30" align="center" valign="bottom" bgcolor="#FFFFFF"><b><u><font size="3" color="#1155CC"><br></br></font></u></b></td>
					<td align="center" valign="bottom" bgcolor="#FFFFFF"><b><u><font size="3" color="#1155CC"><br></br></font></u></b></td>
					<td align="left" valign="bottom" bgcolor="#FFFFFF"><font color="#000000"><br></br></font></td>
				</tr>
				<tr>
					<td height="30" align="left" valign="bottom" bgcolor="#3C3CEB"><font color="#000000"><br></br></font></td>
					<td align="left" valign="bottom" bgcolor="#3C3CEB"><font color="#000000"><br></br></font></td>
					<td align="left" valign="bottom" bgcolor="#3C3CEB"><font color="#000000"><br></br></font></td>
				</tr>
				<tr>
					<td height="30" align="center" valign="bottom" bgcolor="#FFFFFF"><b><u><font size="3" color="#1155CC"><br></br></font></u></b></td>
					<td align="center" valign="bottom" bgcolor="#FFFFFF"><b><u><font size="3" color="#1155CC"><br></br></font></u></b></td>
					<td align="left" valign="bottom" bgcolor="#FFFFFF"><font color="#000000"><br></br></font></td>
				</tr>
				<tr>
					<td colspan="2" height="30" align="center" valign="bottom" bgcolor="#FFFFFF"><b><u><font face="DejaVu Sans" size="5" color="#FF0000">各大平台快车</font></u></b></td>
					<td align="left" valign="bottom" bgcolor="#FFFFFF"><font color="#000000"><br></br></font></td>
				</tr>
				<tr>
					<td height="30" align="center" valign="bottom" bgcolor="#E8E7FC"><b><font size="3" color="#FF0000">front door</font></b></td>
					<td align="center" valign="bottom" bgcolor="#E8E7FC"><b><font size="3" color="#FF0000">backdoor</font></b></td>
					<td align="center" valign="bottom" bgcolor="#E8E7FC"><b><font size="3" color="#FF0000"><br></br></font></b></td>
				</tr>
				<tr>
					<td height="30" align="center" valign="bottom" bgcolor="#FFFFFF"><b><u><font face="DejaVu Sans" size="3" color="#1155CC"><a href="https://www.ebay.com/str/sproutingyeti">ebay 一百购物</a></font></u></b></td>
					<td align="center" valign="bottom" bgcolor="#FFFFFF"><b><u><font face="DejaVu Sans" size="3" color="#1155CC"><a href="https://www.ebay.com/sh/lst/active">ebay 后台</a></font></u></b></td>
					<td align="center" valign="bottom" bgcolor="#FFFFFF"><b><u><font size="3" color="#1155CC"><br></br></font></u></b></td>
				</tr>
				<tr>
					<td height="30" align="center" valign="bottom" bgcolor="#E8E7FC"><b><u><font face="DejaVu Sans" size="3" color="#1155CC"><a href="http://www.loccodecor.com/">Shopify 血拼网</a></font></u></b></td>
					<td align="center" valign="bottom" bgcolor="#E8E7FC"><b><u><font face="DejaVu Sans" size="3" color="#1155CC"><a href="https://locco-decor.myshopify.com/admin">Shopify 后台</a></font></u></b></td>
					<td align="center" valign="bottom" bgcolor="#E8E7FC"><b><u><font size="3" color="#000000"><br></br></font></u></b></td>
				</tr>
				<tr>
					<td height="30" align="center" valign="bottom" bgcolor="#FFFFFF"><b><u><font face="DejaVu Sans" size="3" color="#1155CC"><a href="https://www.etsy.com/shop/LoccoDecor">Etsy 爱词网</a></font></u></b></td>
					<td align="center" valign="bottom" bgcolor="#FFFFFF"><b><u><font face="DejaVu Sans" size="3" color="#1155CC"><a href="https://www.etsy.com/signin">Etsy 后台</a></font></u></b></td>
					<td align="left" valign="bottom" bgcolor="#FFFFFF"><font color="#000000"><br></br></font></td>
				</tr>
				<tr>
					<td height="30" align="center" valign="bottom" bgcolor="#E8E7FC"><b><u><font face="DejaVu Sans" size="3" color="#1155CC"><a href="https://www.walmart.com/ip/Locco-Decor-5-Piece-Acrylic-Liquid-3D-Floating-Motion-Bathroom-Vanity-Accessory-Set-Shell/573950691">Walmart 蛙马特</a></font></u></b></td>
					<td align="center" valign="bottom" bgcolor="#E8E7FC"><b><u><font face="DejaVu Sans" size="3" color="#1155CC"><a href="https://seller.walmart.com/">Walmart 后台</a></font></u></b></td>
					<td align="center" valign="bottom" bgcolor="#E8E7FC"><b><font size="3" color="#FF0000"><br></br></font></b></td>
				</tr>
				<tr>
					<td height="30" align="center" valign="bottom" bgcolor="#FFFFFF"><b><u><font face="DejaVu Sans" size="3" color="#1155CC"><a href="https://www.amazon.com/s?me=AUW2QBDGYN4Q4">Amazon 一马纵</a></font></u></b></td>
					<td align="center" valign="bottom" bgcolor="#FFFFFF"><b><u><font face="DejaVu Sans" size="3" color="#1155CC"><a href="http://sellercentral.amazon.com/">Amazon 后台</a></font></u></b></td>
					<td align="left" valign="bottom" bgcolor="#FFFFFF"><font color="#000000"><br></br></font></td>
				</tr>
				<tr>
					<td height="30" align="left" valign="bottom"><font color="#000000"><br></br></font></td>
					<td align="left" valign="bottom"><font color="#000000"><br></br></font></td>
					<td align="left" valign="bottom"><font color="#000000"><br></br></font></td>
				</tr>
				<tr>
					<td height="30" align="left" valign="bottom" bgcolor="#3C3CEB"><font color="#000000"><br></br></font></td>
					<td align="left" valign="bottom" bgcolor="#3C3CEB"><font color="#000000"><br></br></font></td>
					<td align="left" valign="bottom" bgcolor="#3C3CEB"><font color="#000000"><br></br></font></td>
				</tr>
				<tr>
					<td height="30" align="center" valign="bottom" bgcolor="#FFFFFF"><b><u><font size="3" color="#1155CC"><br></br></font></u></b></td>
					<td align="center" valign="bottom" bgcolor="#FFFFFF"><b><u><font size="3" color="#1155CC"><br></br></font></u></b></td>
					<td align="center" valign="bottom" bgcolor="#FFFFFF"><b><u><font size="3" color="#1155CC"><br></br></font></u></b></td>
				</tr>
				<tr>
					<td colspan="2" height="30" align="center" valign="bottom" bgcolor="#E8E7FC"><b><u><font face="DejaVu Sans" size="5" color="#FF0000">工作平台快车</font></u></b></td>
					<td align="center" valign="bottom" bgcolor="#E8E7FC"><b><font size="3" color="#FF0000"><br></br></font></b></td>
				</tr>
				<tr>
					<td height="30" align="center" valign="bottom" bgcolor="#FFFFFF"><b><u><font size="3" color="#1155CC"><a href="https://outlook.office365.com/mail/inbox/id/AAQkADRiYmY2ZGQxLWExZmYtNDcxYS05NWU1LTlhOTRlN2IwYzgyMAAQAPmMih7PgaVHl4LR518XaMU%3D">Hotmail</a></font></u></b></td>
					<td align="center" valign="bottom" bgcolor="#FFFFFF"><b><u><font size="3" color="#1155CC"><a href="https://www.soldeazy.com/ssl/soldeazy/login">Sold Easy</a></font></u></b></td>
					<td align="center" valign="bottom" bgcolor="#FFFFFF"><b><u><font size="3" color="#1155CC"><a href="https://c16.qbo.intuit.com/app/importdata?entity=Items">Quickbook Upload</a></font></u></b></td>
				</tr>
				<tr>
					<td height="30" align="center" valign="bottom" bgcolor="#E8E7FC"><b><u><font size="3" color="#1155CC"><a href="https://ss5.shipstation.com/">Shipstaion</a></font></u></b></td>
					<td align="center" valign="bottom" bgcolor="#E8E7FC"><b><u><font size="3" color="#1155CC"><a href="https://mail.google.com/mail/u/0/#inbox">Gmail</a></font></u></b></td>
					<td align="center" valign="bottom" bgcolor="#E8E7FC"><b><u><font size="3" color="#1155CC"><a href="https://www.facebook.com/LoccoDecor">facebook</a></font></u></b></td>
				</tr>
				<tr>
					<td height="30" align="center" valign="bottom" bgcolor="#FFFFFF"><b><u><font size="3" color="#1155CC"><a href="https://www.instagram.com/locco_decor">Instagram</a></font></u></b></td>
					<td align="center" valign="bottom" bgcolor="#FFFFFF"><b><u><font face="DejaVu Sans" size="3" color="#1155CC"><a href="https://trello.com/b/FMTzfctK/%E5%96%9C%E9%A9%AC%E6%8B%89%E9%9B%85%E7%81%AB%E7%83%88%E7%8C%BF">Trello( 火烈猿 )</a></font></u></b></td>
					<td align="center" valign="bottom" bgcolor="#FFFFFF"><b><u><font size="3" color="#1155CC"><br></br></font></u></b></td>
				</tr>
				<tr>
					<td height="30" align="center" valign="bottom" bgcolor="#E8E7FC"><b><u><font size="3" color="#1155CC"><br></br></font></u></b></td>
					<td align="center" valign="bottom" bgcolor="#E8E7FC"><b><u><font face="DejaVu Sans" size="3" color="#1155CC"><a href="https://trello.com/b/jrMjtM5S/crystal-information">Trello( 矿标 )</a></font></u></b></td>
					<td align="center" valign="bottom" bgcolor="#E8E7FC"><b><u><font size="3" color="#1155CC"><br></br></font></u></b></td>
				</tr>
				<tr>
					<td height="30" align="left" valign="bottom" bgcolor="#1A1AE7"><font color="#000000"><br></br></font></td>
					<td align="left" valign="bottom" bgcolor="#1A1AE7"><font color="#000000"><br></br></font></td>
					<td align="left" valign="bottom" bgcolor="#1A1AE7"><font color="#000000"><br></br></font></td>
				</tr>
				<tr>
					<td colspan="3" rowspan="2" height="42" align="center" valign="bottom" bgcolor="#FFFFFF"><b><u><font face="DejaVu Sans" size="5" color="#FF0000">Crystal Allies 咨询快车</font></u></b></td>
					</tr>
				<tr>
					</tr>
				<tr>
					<td height="30" align="center" valign="bottom" bgcolor="#FFFFFF"><b><u><font size="3" color="#1155CC"><a href="https://docs.google.com/spreadsheets/d/1v-xQ0jxEKIowd8ov1THEJbpNmV9oauZ5rMB0X4A3un4/">DH Container Cost Calculation</a></font></u></b></td>
					<td align="center" valign="bottom" bgcolor="#FFFFFF"><b><font size="3" color="#000000">CA show info origin</font></b></td>
					<td align="left" valign="bottom" bgcolor="#FFFFFF"><font color="#000000"><br></br></font></td>
				</tr>
				<tr>
					<td height="30" align="center" valign="bottom" bgcolor="#E8E7FC"><b><u><font size="3" color="#1155CC"><a href="https://docs.google.com/spreadsheets/d/1Exw7isYP-FbOlQg-dfJkFDLMgq9sHf5SQEluD4IX7Y4">Master Stone Abbreviation</a></font></u></b></td>
					<td align="center" valign="bottom" bgcolor="#E8E7FC"><b><font size="3" color="#000000">Update abbreaviation with CA</font></b></td>
					<td align="left" valign="bottom" bgcolor="#E8E7FC"><font color="#000000"><br></br></font></td>
				</tr>
				<tr>
					<td height="30" align="center" valign="bottom" bgcolor="#FFFFFF"><b><u><font size="3" color="#1155CC"><a href="https://docs.google.com/spreadsheets/d/1f3PkmSeWyHtVj6PWnOUnECvPCE3GFDhlxPSL-EhuG-4">Crystal Allies - Specimen Database</a></font></u></b></td>
					<td align="center" valign="bottom" bgcolor="#FFFFFF"><b><font size="3" color="#000000">CA info with selling info</font></b></td>
					<td align="center" valign="bottom" bgcolor="#FFFFFF"><b><font size="3" color="#5F6368">Number</font></b></td>
				</tr>
			</tbody></table>
    </div>
    )
}

export default YetiPage;

import axios from 'axios';
import React, { useState} from 'react';
function Upload(){
    //const [formData, setFormData] = useState({});
    const [files, setUploadFile] = useState();
    const [skuinput,setSkuInput] = useState();
    const [status, setStatus] = useState("Waiting for updates");
    const [urls,setreturnlink] =useState("links: ");


    const onChangeFiles = (event) => {
        setUploadFile(event.target.files);
        //console.log(event.target.files[0]);
    }
    const onchangesku = (event) => {
      setSkuInput(event.target.value);
      //console.log(event.target.files[0]);
  }


    const handleSubmitForm = (event) => {
        event.preventDefault();
        sendUpdate();
    }

    const sendUpdate = () => {
        const formData = new FormData();

        for (let i = 0 ; i < files.length ; i++) {
          formData.append("files", files[i]);
      }
      const base_folder_path = "https://flamingyetis.com/ftp/" + formData.get("skunumber") + "/"

      for (let i = 0 ; i < files.length ; i++) {
        formData.append("urls", (base_folder_path+files[i]));
    }  
      formData.append("skunumber", skuinput);
      console.log(formData.getAll("files"));
      console.log("sku is :" +  formData.get("skunumber"));
      console.log("sku is :" +  formData.get("urls"));

      axios({
            method: "POST",
            url: "https://flamingyetis.com/file-upload-test",
            data: formData,
            headers: { "Content-Type":  "multipart/form-data"},
        })
        .then(function (response) {
            console.log(response);
            //setStatus(response.data.updated);
            setStatus(response.data)
        })
        .catch(function (response) {
            console.log(response);
            setStatus(response.data)
        });
    }

    return(
      <form onSubmit={handleSubmitForm}>
        <input type="text" placeholder="SKU Number" name="skunumber" onChange={onchangesku}></input>
        <div>
            <input
              onChange={onChangeFiles}
              type="file"
              name="files"
              multiple
              accept="image/png, image/jpeg, image/jpg, video/*"/>
      </div>
        <br />
         <input type="submit" value="Send" />
        <br/>
         <div className="col-md-8 mb-3"><h2>{status}</h2></div>
         <div className="col-md-8 mb-3"><h2>{urls}</h2></div>
        
      </form>

    );
  }

export default Upload;
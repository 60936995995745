import React from 'react';
import ReactDOM from "react-dom";

const openEmails = url => {
    window.open("https://mail.google.com/mail/u/0/#inbox",'_blank','noopener,noreferrer');
}

function HomePage(){
    return(
        <div>
                    <div className="container px-4 py-5" id="featured-3">
                <h2 className="pb-2 border-bottom">Choose what to do...</h2>
                <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
                    <div className="feature col">
                        <div className="feature-icon bg-primary bg-gradient">
                    </div>
                        <a href="https://docs.google.com/spreadsheets/d/1JhcMHfNITl6aXqT9TeP_swbyls4Zv6BWWDrbGAfQG7U/edit#gid=0" className="icon-link">
                        <h2>Shopify Sheet主要sheet V1.0 <br></br></h2>
                        </a>
                       <h4>SHOPIFY上新草稿<br></br></h4>    
                        <a href="https://docs.google.com/spreadsheets/d/1VTXsZXun25eModcPEAv6FepOspji6txeNSE08rZ4dVo/edit#gid=0" className="icon-link">
                        <h2>辅助sheet <br></br></h2>
                        </a>

           
                    </div>

                    <div className="feature col">
                        <div className="feature-icon bg-primary bg-gradient">
                        </div>
                        <a href="https://docs.google.com/spreadsheets/d/1-W3Lxy75AEr-TMHVjc24mUO9jkZHEXraRQdEXhwDlr8/edit#gid=0" className="icon-link">
                            <h2>FDC Internal Tracker <br></br>统计表-数据库 </h2>
                        </a>
                        <a href="https://docs.google.com/spreadsheets/d/1xtWZq9ENhu7D3UzVeCSRB1W9itp8J7oTmphkTuRkZ2c/edit#gid=1335479638" className="icon-link">
                            <h2>FDC Internal Tracker <br></br>入柜编辑数据库 </h2>
                        </a>                        
                        <h4>=<br></br>=</h4> 
                    </div>


                    <div className="feature col">
                        <div className="feature-icon bg-primary bg-gradient">
                        </div>
                        <a href="https://docs.google.com/spreadsheets/d/15-_lBkD04YOMcc7o65_KwFstmZkauZGDwwPEKE34OnY/edit#gid=666599723" className="icon-link">
                            <h2>List Creator Inventory Add <br></br>List 辅助</h2>
                        </a>
                        <h4>=<br></br> = </h4>
                    </div>


                     <div className="feature col">
                        <div className="icon-link">
                        <h2>
                        <a href="https://docs.google.com/spreadsheets/d/1FMsw9Fes-WFjNIdRWgQaxgKq6zJfL_OnP7RjS0e6__s/edit" className= "iconlink">
                            <p>Amazon Inventory</p><p> FBA shipment</p>
                        </a></h2>
                        </div>
                    </div>


                    <div className="feature col">
                        <div className="feature-icon bg-primary bg-gradient">
                        </div>
                        
                        <a href="https://flamingyetis.com/ftp3/reimburse.xlsx" className="icon-link">
                            <h2>Reimburse excel</h2>
                        </a>
                        <h4>filler.</h4>
                    </div>

                    <div className="feature col">
                        <div className="feature-icon bg-primary bg-gradient">
                        </div>
                        <h2>Fillers</h2>                        
                        <a href="" className="icon-link">
                            <h4>filler1</h4>
                        </a>
                        <a href="" className="icon-link">
                            <h4>filler2</h4>
                        </a>                        

                    </div>
                    <div className="feature col">
                        <div>
                            <div id="container"></div>
                            <table>
                                    <tr><button className="button-86" onClick={() => openEmails()}>Open links 备用</button></tr>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
    
export default HomePage;
        

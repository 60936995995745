//import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route } from "react-router-dom";
import HomePage from './components_new/home_page';
import NewPage from './components_new/new_page';
import EditDraftPage from './components_new/edit_draft';
import Navbar from './components_new/navbar';
import YetiPage from './components_new/yeti';
import LocatorPage from './components_new/item_locator';
import Upload from './components_new/upload';
import FDC from './components_new/FDC';

//import ReactGA from 'react-ga';
//Use react-bootstrap for components ;)

function App() {
  return (
    <Router>
      <div className="container">
        <Navbar />
        <br />
        <Route path="/" exact><HomePage /></Route>
        <Route path="/new" exact><NewPage /></Route>
        <Route path="/edit_draft" exact><EditDraftPage /></Route>
        <Route path="/item_locator" exact><LocatorPage /></Route>
        <Route path="/yeti_bookmark" exact><YetiPage /></Route>
        <Route path="/upload" exact><Upload /></Route>
        <Route path="/FDC" exact><FDC /></Route>

      </div>
    </Router>
  )
}
export default App;

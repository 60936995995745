import React from 'react';
import LDlogo from './logo.png';
//import ReactDOM from "react-dom";



function Navbar(){
    return (
          <nav className="navbar navbar-expand-lg navbar-light me-auto mb-lg-2">
            <img className="logo-image" src={LDlogo} alt="logo.png" />

            <div className="container">
            <a className="navbar-brand" href="/">Locco Decor Product Upload Tool</a>
            <ul className="navbar-nav me-auto mb-auto mb-lg-0">
            <a className="container border" href="/new">New product</a>
            <a className="container border" href="/edit_draft">Edit Drafts</a>
            <a className="container border" href="/item_locator">Item Locator</a>
            <a className="container border" href="/yeti_bookmark">Yeti Page</a>
            <a className="container border" href="/upload">upload Page</a>
            <a className="container border" href="/FDC">FDC Page</a>
            </ul>
            </div>
            <div>            
            </div>
            </nav>

    )
}

export default Navbar;





{/* 

        <nav className="navbar navbar-expand-lg navbar-light me-auto mb-lg-2">
        <div className="collapse navbar-collapse" id="navbarSupportedContent"> 
        <ul className="navbar-nav me-auto">
            <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" aria-haspopup="true">
                    Actions
                </a>
            <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                <li><a className="navbarDropdown dropdown-item" href="/new">New</a></li>
                <li><a className="dropdown-item" href="/edit_draft">Edit Drafts</a></li>
                <li><a className="dropdown-item" href="/item_locator">Item Locator Page</a></li>
                <li><a className="dropdown-item" href="/yeti_bookmark">Yeti Page</a></li>
                <li><a className="dropdown-item" href="/upload">upload Page</a></li>
            </ul>
            </li>
        </ul>
        </div>
        </nav>
*/}
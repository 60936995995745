import React from 'react';
import ReactDOM from "react-dom";

const openEmails = url => {
    window.open("https://mail.google.com/mail/u/0/#inbox",'_blank','noopener,noreferrer');
    window.open("https://mail.google.com/mail/u/1/#inbox",'_blank','noopener,noreferrer');
    window.open("https://mail.google.com/mail/u/5/#inbox",'_blank','noopener,noreferrer');
    window.open("https://mail.google.com/mail/u/7/#inbox",'_blank','noopener,noreferrer');
    window.open("https://outlook.live.com/owa/?nlp=1&RpsCsrfState=5290369e-e8c7-6475-6bf8-a2538326413b&f=255&MSPPError=-2147207876&username=contact%40loccodecor.com",'_blank','noopener,noreferrer');

}

const openPlatforms = url => {
    window.open("https://partners.wayfair.com/",'_blank','noopener,noreferrer');
    window.open("https://seller.walmart.com/",'_blank','noopener,noreferrer');
    window.open("https://www.ebay.com/sh/lst/active",'_blank','noopener,noreferrer');
    window.open("http://back.loccodecor.com/",'_blank','noopener,noreferrer');
}
const openERPs = url => {
    window.open("https://app.sellbrite.com/dashboard",'_blank','noopener,noreferrer');
    window.open("https://sellerportal.deliverr.com/inventory",'_blank','noopener,noreferrer');
    window.open("https://ship5.shipstation.com/orders/awaiting-shipment",'_blank','noopener,noreferrer');
}
const openOldBookMarks = url => {
    window.open("https://docs.google.com/spreadsheets/d/e/2PACX-1vTsBq-tfI1h7nIXySSfAjAU29U71l1WhlGJXEBlLHY7SkYrD-jw2nutRPnh-u84SAjEnZS7jU9_XyGJ/pubhtml?gid=372493842&single=true",'_blank','noopener,noreferrer');
    window.open("https://docs.google.com/spreadsheets/d/e/2PACX-1vSpuUviL18b5FwJga-tyMhRjUY8CIw2PlzHCoOrewg-OruvFXzgpo9C4eZiidd7uejwRt0OjZWlYJan/pubhtml",'_blank','noopener,noreferrer');
}


function HomePage(){
    return(
        <div>
            <div className="container px-4 py-5" id="featured-3">
                <h2 className="pb-2 border-bottom">Choose what to do...</h2>
                <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
                    <div className="feature col">
                        <div className="feature-icon bg-primary bg-gradient">
                    </div>
                        <a href="/new" className="icon-link">
                        <h2>New Product <br></br>创建新产品</h2>
                        </a>
                        <h5>Add new product information.<br></br>
                        You only need to fill out SKU and batch number to create the draft</h5>              
                    </div>

                    <div className="feature col">
                        <div className="feature-icon bg-primary bg-gradient">
                        </div>
                        <a href="/edit_draft" className="icon-link">
                            <h2>Edit_Draft <br></br>编辑草稿 </h2>
                        </a>
                       <h5> Lookup and edit an existing product's information. <br></br>
                            Edit everything in drafts, once publish, it will move to product listing among thousands of skus.
                       </h5>
                    </div>


                    <div className="feature col">
                        <div className="feature-icon bg-primary bg-gradient">
                        </div>
                        
                        <a href="/item_locator" className="icon-link">
                            <h2>Item Locator <br></br>寻人棒</h2>
                        </a>
                        <h5>View items location.<br></br> Item Locator will help finding all information that belong to a sku in our database </h5>
                    </div>


                     <div className="feature col">
                        <div className="icon-link">
                        <h2>
                        <a href="https://www.plex.tv/sign-in/?forward=https://www.plex.tv/" className= "iconlink">
                            Game of Throne
                            <p>冰与火之歌</p>
                        </a></h2>
                        </div>
                    </div>


                    <div className="feature col">
                        <div className="feature-icon bg-primary bg-gradient">
                        </div>
                        
                        <a href="/yeti_bookmark" className="icon-link">
                            <h2>yeti book marks</h2>
                        </a>
                        <h5>customizable bookmark.</h5>
                    </div>

                    <div className="feature col">
                        <div className="feature-icon bg-primary bg-gradient">
                        </div>
                        <h2>办公用链接/Useful forms</h2>                        
                        <a href="https://flamingyetis.com/ftp2/MMY/coolpai%20fillable.pdf" className="icon-link">
                            <h5>coolpai fillable</h5>
                        </a>
                        <a href="https://flamingyetis.com/ftp2/MMY/ISF_sample.doc" className="icon-link">
                            <h5>ISF sample</h5>
                        </a>                        

                    </div>

                    <div className="feature col">
                        <div>
                            <div id="container"></div>
                            <table>
                                    <tr><button className="button-86" onClick={() => openEmails()}>Open Emails</button></tr>
                                    <tr><button className="button-86" onClick={() => openPlatforms()}>Open platforms</button></tr>
                                    <tr><button className="button-86" onClick={() => openERPs()}>Open ERPS</button></tr>
                                    <tr><button className="button-86" onClick={() => openOldBookMarks()}>Open Old Bookmarks</button></tr>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
    
export default HomePage;
        

import React, { useState, useEffect} from 'react';
import axios from 'axios';
import './item_locator.css';




function LocatorPage(){
  const [skuData, setSkuData] = useState({});
  const [formData, setFormData] = useState({empty: 'true'});
  const [searchSku, setSearchSku] = useState({ password: '', SKU: '' });
    useEffect(() => {
	    axios.get('https://flamingyetis.com/lookupProducts')
	    .then(function (response) {
	      console.log(response);
	      setSkuData(response);
	    })
	    .catch(function (err) {
	      console.log(err);
	    });
    }, []);

    const processPics = (strPics) => {
      if (strPics !== undefined){
        if (strPics.length == 0){
          return "No picture"
        }
        var listPics = strPics.split(",");
        let imgs = [];
        let i = 0;
        for (i = 0; i < listPics.length; i++){
          imgs.push(<img key={listPics[i] + "key"}  src={listPics[i]} alt="Picture of mineral" width="50" height="50" className="img-fluid"/>);
          if (i === 1){
            return imgs;
          }
        }  
        return imgs;    
      }
      return "Empty pic list";
    } 

    const processPics2 = (strPics) => {
      if (strPics !== undefined){
      const listPics = strPics.split(",");
      let imgs = [];
      for (let i = 0; i < listPics.length; i++){
        imgs.push(<img key={listPics[i] + "key"}  src={listPics[i]} alt={listPics[i]} width="200px" height="200px" className="img-fluid"/>)
      }
      return imgs;
    }
    return "NONE";
    }


    const handleDelete = (SKU) => {
      console.log(JSON.stringify({"SKU": SKU}));
      axios({
        method: "POST",
        url: "https://flamingyetis.com/deleteProduct",
        data: JSON.stringify({"SKU": SKU}),
        headers: { "Content-Type": "application/json" },
         })
           .then(function (response) {
             console.log(response);
             window.location.reload(false);
           })
           .catch(function (response) {
             console.log(response);
           });	
    }
    
    const renderRowDraftSKU = (rowInfo) => {
      return(
        <tr>
          <td key={rowInfo[0] + "sku"}>{rowInfo[0]}</td>
          <td key={rowInfo[0] + "img"}>{processPics(rowInfo[4])}</td>
          <td key={rowInfo[0] + "mbn"}>{rowInfo[1]}</td>
          <td key={rowInfo[0] + "location"}>{rowInfo[7]}</td>          
          <td key={rowInfo[0] + "boxnumber"}>{rowInfo[6]}</td>  
          <td key={rowInfo[0] + "delete"}><button onClick={() => {handleDelete(rowInfo[0]);}}>Delete</button></td> 
        </tr>
      )
    }


    const handleChangeSearch = (event) => {
      setSearchSku({ ...searchSku, [event.target.name]: event.target.value });
  }   
    const handleSubmitSearch = (event) => {
      event.preventDefault();
      getProduct();
}   
const getProduct = () => {
  axios({
    method: "GET",
    url: "https://flamingyetis.com/lookup",
    params: {
        "password": searchSku.password,
        "SKU": searchSku.sku
    },
    //headers: { "Content-Type": "application/json" },
  })
    .then(function (response) {
      setFormData({});  // Resets form data when new product is requested
      setFormData(response.data);
      console.log(formData);
    })
    .catch(function (response) {
      console.log(response);
    });
}

const renderForm = (props) => {
  return (
    <div className="container">
        <h5>Alright found it</h5>
        <div className="container border border-success">
          <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
            <h3>Status Info </h3>
            </div>
         <div className="row">
              <div className="col-md-3 mb-3 border">
                <div className="row border"><h5><u>SKU</u></h5></div>
                <div className="row">{props.sku}</div>
              </div>
              <div className="col-md-3 mb-3 border">
                <div className="row border"><h5><u>Batch</u></h5></div>
                <div className="row">{props.mbn}</div>
              </div>
              <div className="col-md-2 mb-3 border">
                <div className="row border"><h5><u>stauts</u></h5> </div>
                <div className="row">{props.min_status}</div>
              </div>   
              <div className="col-md-2 mb-3 border">
              <button onClick={() => {handleDelete(props.sku);}}>Delete</button>              
              </div>                                         
         </div>

          <div className="row">
            <div className="col-md-2 mb-3 border">
              <div className="row border"> <h5><u>found under</u></h5> </div>
              <div className="row">{props.found}</div>
            </div>
            <div className="col-md-2 mb-3 border">
              <div className="row border"> <h5><u>payment</u></h5> </div>
              <div className="row ">{props.payment}</div>
            </div>
          </div>
        </div>

        <div className="col-md mb-3 border border-success">
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}><h3> Update Info </h3></div>
              <div className="row justify-content-center">
                <div className="col-auto">
                  <table className="table table-responsive">
                    <tr>
                      <th><u>Created Date:</u></th>
                      <th>{props.created_date}</th>
                    </tr>
                    <tr>
                      <th><u>Modified Date:</u></th>
                      <th>{props.modified_date}</th>
                    </tr>
                    <tr>
                      <th><u>listing Date:</u></th>
                      <th>{props.listing_date}</th>
                    </tr>
                  </table>
                </div>
              </div>
            </div>

        <div className="container border border-success ">
         <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
           <h3>Price info</h3>
         </div>
         <div className="row border">
            <div className="col-md-4 mb-3 border">
              <div className="row border"><h5><u>Cost and Cost/kg</u></h5></div>
              <div className="row">$ {props.cost}</div>
              <div className="row">$ {props.cost_kg}</div>
            </div>
            <div className="col-md-4 mb-3 border">
              <div className="row border"><h5><u>wholesale and wholesale/kg </u></h5> </div>
              <div className="row">$ {props.w_price}</div>
              <div className="row">$ {props.w_p_kg}</div>

            </div>
            <div className="col-md-4 mb-3 border">
              <div className="row border"><h5><u>MSRP</u></h5> </div>
              <div className="row">$ {props.price}</div>
            </div>
          </div>
        </div>

        <div className="container border border-success ">
         <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
           <h3>Price info</h3>
         </div>
         <div className="row border">
            <div className="col-md-4 mb-3 border">
              <div className="row border"><h5><u>Stone Category Type</u></h5></div>
              <div className="row"> {props.s_cat}</div>
            </div>
            <div className="col-md-4 mb-3 border">
              <div className="row border"><h5><u>color</u></h5></div>
              <div className="row"> {props.s_col}</div>
            </div>
            <div className="col-md-4 mb-3 border">
              <div className="row border"><h5><u>origin country</u></h5></div>
              <div className="row"> {props.or_c}</div>
            </div>
            <div className="col-md-4 mb-3 border">
              <div className="row border"><h5><u>origin seconday</u></h5></div>
              <div className="row"> {props.or_s}</div>
            </div>
            <div className="col-md-4 mb-3 border">
              <div className="row border"><h5><u>stone shape</u></h5></div>
              <div className="row"> {props.mss}</div>
            </div>
            <div className="col-md-4 mb-3 border">
              <div className="row border"><h5><u>stone type</u></h5></div>
              <div className="row"> {props.mst}</div>
            </div>
          </div>
        </div>
        <div className="container border border-success ">
         <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
           <h3>Media info</h3>
         </div>
         <div className="row border">
            <div className="col-md-6 mb-3 border">
              <div className="row border"><h5><u>images</u></h5></div>
              <div className="row"> < div>{props.img_h}</div></div>
              <div> {processPics2(props.img_h)} </div>
            </div>
            <div className="col-md-6 mb-3 border">
              <div className="row border"><h5><u>videos </u></h5> </div>
              <div className="row"> {props.vl}</div>
            </div>

          </div>
        </div>




 
    </div>   
    )
}

    return(
        <div className="container">
            <div className="container">
                <h1>SKU published 已发布</h1>
                <h4>Displaying 20, Sort by Most recent to oldest</h4>
                <h4>刚发布的20个产品，从新到旧排列.</h4>
                <br/>
                <div className="scrolls">
                  <table className="table border" cellSpacing="0" width="100%">
                    <thead >
                      <tr >
                      <th className="priority-1" width="10%">SKU</th>
                      <th className="priority-2" width="15%">Img</th>
                      <th className="priority-3" width="15%">Batch </th>
                      <th className="priority-4" width="15%">Rack </th>
                      <th className="priority-5" width="15%">Box </th>
                      <th className="priority-6" width="15%">Kill </th>
                      </tr>
                    </thead>
                    <tbody className="specialtbody">
                      {typeof skuData.data !== "undefined" ? skuData.data.sku_list.map(d => renderRowDraftSKU(d)) : null}  
                    </tbody>
                  </table>
                </div>
            </div>
            <div className="container">
                <form onSubmit={handleSubmitSearch}>
                    <label>Password 密码</label>
                    <input type="text" className="form-control" id="password" name="password" placeholder="Password" value={searchSku.password} onChange={handleChangeSearch} required />
                    <label>SKU Number</label>
                    <input type="text" className="form-control" id="sku" name="sku" placeholder="Enter SKU to search" value={searchSku.sku} onChange={handleChangeSearch} required />
                    <br></br>
                  <button className="submitbutton submitbutton1" onSubmit={handleSubmitSearch}>submit/提交</button>
                </form>
            </div>
            <div className="container">
            {formData.empty==="true" ? "Welcome to search function 欢迎查找" : formData.found==="not found" ? "Not Found" : renderForm(formData)}           
             </div>

        </div>
           
    )
}

export default LocatorPage;
